import React from "react";
import Button from "../Button";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Icon } from "@progress/kendo-react-common";
import "./HomePageSubHeader.css";

const HomePageSubHeader = () => {
  const opsOptions = [
    { text: "8 ops", value: 1 },
    { text: "6 ops", value: 2 },
  ];

  return (
    <div className="submenu-container">
      <Button icon="user" className="submenu-button">
        Doctors
      </Button>

      <div className="hyperlinks">
        <a href="#" style={{ color: "red" }}>
          AL
        </a>
        <a href="#" style={{ color: "green" }}>
          TC
        </a>
        <a href="#" style={{ color: "#D6C850" }}>
          JP
        </a>
        <a href="#" style={{ color: "blue" }}>
          TKD
        </a>
        <a href="#" style={{ color: "purple" }}>
          AH
        </a>
        <a href="#" style={{ color: "red" }}>
          HG
        </a>
        <a href="#" style={{ color: "green" }}>
          ASH
        </a>
      </div>

      <div className="spacer"></div>

      <div className="submenu-right">
        <Button className="submenu-button">To Schedule (26)</Button>
        <Button className="submenu-button">Waiting</Button>
      </div>

      <DropDownList
        data={opsOptions}
        defaultItem="8 ops"
        className="submenu-dropdown"
      />

      <div className="icon-group">
        <Icon name="bell" className="submenu-icon" />
        <Icon name="comment" className="submenu-icon" />
        <Icon name="gear" className="submenu-icon" />
      </div>
    </div>
  );
};

export default HomePageSubHeader;
