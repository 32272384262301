import axios from 'axios';

const BASE_URL = 'http://localhost:5093/api/clinic';

const apiService = {
  // Get clinics
  getClinics: (accessToken) => {
    return axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  
  // You can define other services for POST, PUT, DELETE here.
};

export default apiService;
