import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/templates/Layout/Layout';
import AppRouter from './AppRoutes';
import "@progress/kendo-theme-default/dist/all.css";

const App = () => {
  // Add this in your root component or App.js file
  if (typeof window !== 'undefined') {
    window.onerror = function (message, source, lineno, colno, error) {
        const resizeObserverErrMsg = 'ResizeObserver loop completed with undelivered notifications.';
        console.log("cgeck,message",message, message===resizeObserverErrMsg)

        // Suppress the ResizeObserver error by returning true
        if (message.startsWith(resizeObserverErrMsg)) {
          console.log("cgeck,message..",message, message===resizeObserverErrMsg)

            return true;
        }

        // Log other errors (optional)
        console.error(message, source, lineno, colno, error);
    };
}


  return (
    <Router>
      {/* <Layout> */}
        <AppRouter />
      {/* </Layout> */}
    </Router>
  );
};

export default App;
