import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
      isAuthenticated: false,
    },
    reducers: {
      loginSuccess(state,action) {
        console.log("lofin",action.payload)
        state.isAuthenticated = true;
        state.accessToken = action.payload.accessToken;
        state.user = action.payload; // Store user data

      },
      logout(state) {
        state.isAuthenticated = false;
      },
    },
  });
  
  export const { loginSuccess, logout } = authSlice.actions;
  

export default authSlice.reducer;
