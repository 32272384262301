import React from 'react';
import { Icon } from '@progress/kendo-react-common'; // Kendo UI icons
import './HomePageDrawer.css'; // Custom styles

const SchedulerDrawer = () => {
  return (
    <div className="drawer-container">
      {/* Example of 10 icons wrapped in a circular container */}
      <div className="icon-circle">
        <Icon name="calendar" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="user" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="gear" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="bell" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="comment" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="home" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="graph" className="drawer-icon" />
      </div>
      <div className="icon-circle">
        <Icon name="question-circle" className="drawer-icon" />
      </div>
    </div>
  );
};

export default SchedulerDrawer;
