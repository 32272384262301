import * as React from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import DynamicTabbedForm from "../organisms/DynamicForm"; // Import the form component
import "../organisms/DynamicForm.css"; // Custom styles for the popup

const AddPatient = ({ show, handleOnClick }) => {
  const anchor = React.useRef(null);

  // Example form structure for tabs and sections (you can modify as needed)
  const formStructure = {
    title: "Profile",
    tabs: [
      {
        name: "Demographics",
        sections: [
          {
            sectionName: "Demographic",
            fields: [
              {
                label: "Preferred Office",
                name: "preferredOffice",
                type: "select",
                required: true,
                options: [
                  { label: "Smiles Clinic", value: "smiles" },
                  { label: "Downtown Clinic", value: "downtown" },
                ],
              },
              {
                label: "Middle Name",
                name: "middleName",
                type: "input",
                placeholder: "Enter middle name",
              },
              {
                label: "Type",
                name: "type",
                type: "select",
                options: [
                  { label: "Regular", value: "regular" },
                  { label: "VIP", value: "vip" },
                ],
              },
              {
                label: "Birthdate",
                name: "birthdate",
                type: "date",
                inputType: "date",
                required: true,
              },
            ],
          },
          {
            sectionName: "Family",
            fields: [
              {
                label: "Guarantor",
                name: "guarantor",
                type: "input",
                placeholder: "Enter guarantor",
              },
              {
                label: "Change Family",
                name: "changeFamily",
                type: "button",
                text: "Change Family",
              },
              {
                label: "Self",
                name: "self",
                type: "checkbox",
              },
            ],
          },
        ],
      },
      {
        name: "Address",
        sections: [
          {
            sectionName: "Primary Address",
            fields: [
              {
                label: "Street",
                name: "street",
                type: "input",
                required: true,
                placeholder: "Enter street",
              },
              {
                label: "City",
                name: "city",
                type: "input",
                placeholder: "Enter city",
              },
              {
                label: "State",
                name: "state",
                type: "input",
                placeholder: "Enter state",
              },
              {
                label: "Postal Code",
                name: "postalCode",
                type: "input",
                placeholder: "Enter postal code",
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <div>
      <Popup
        anchor={anchor.current && anchor.current.element}
        show={show}
        popupClass={"popup-content"}
      >
        <div className="popup-header">
          <span>Profile</span>
          <Button
            type="button"
            icon="close"
            look="bare"
            className="popup-close-button"
            onClick={handleOnClick}
          />
        </div>

        {/* Form content inside the popup */}
        <div className="popup-body">
          <DynamicTabbedForm
            tabs={formStructure.tabs}
          />
        </div>
      </Popup>
    </div>
  );
};

export default AddPatient;
