const baseData = [{
  "TaskID": 4,
  "OwnerID": 2,
  "Title": "Patient: John Doe - Root Canal Treatment",
  "Description": "Root canal procedure for John Doe",
  "StartTimezone": null,
  "Start": "2024-06-09T09:00:00.000Z",
  "End": "2024-06-09T10:30:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 5,
  "OwnerID": 2,
  "Title": "Patient: Sarah Connor - Teeth Whitening",
  "Description": "Teeth whitening procedure",
  "StartTimezone": null,
  "Start": "2024-06-10T07:00:00.000Z",
  "End": "2024-06-10T08:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 6,
  "OwnerID": 2,
  "Title": "Patient: Charlie Brown - Consultation",
  "Description": "Initial dental consultation",
  "StartTimezone": null,
  "Start": "2024-06-11T11:30:00.000Z",
  "End": "2024-06-11T12:30:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 7,
  "OwnerID": 3,
  "Title": "Staff Meeting - Monthly Review",
  "Description": "Monthly performance and patient review meeting",
  "StartTimezone": null,
  "Start": "2024-06-12T09:00:00.000Z",
  "End": "2024-06-12T10:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 9,
  "OwnerID": 2,
  "Title": "Patient: Alex Smith - Dental Cleaning",
  "Description": "Routine dental cleaning",
  "StartTimezone": null,
  "Start": "2024-06-14T11:00:00.000Z",
  "End": "2024-06-14T11:45:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 12,
  "OwnerID": 1,
  "Title": "Patient: Emily White - Fillings",
  "RoomID": 1,
  "Description": "Composite fillings for Emily White",
  "StartTimezone": null,
  "Start": "2024-06-24T08:30:00.000Z",
  "End": "2024-06-24T10:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 14,
  "OwnerID": 3,
  "RoomID": 2,
  "PersonID": 3,
  "Title": "Staff Training - New Equipment",
  "Description": "Training session for new X-ray machine",
  "StartTimezone": null,
  "Start": "2024-06-24T10:00:00.000Z",
  "End": "2024-06-24T11:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 15,
  "OwnerID": 1,
  "Title": "Patient: David Miller - Orthodontic Consultation",
  "Description": "Consultation for potential braces",
  "StartTimezone": null,
  "Start": "2024-06-25T12:00:00.000Z",
  "End": "2024-06-25T12:45:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 16,
  "OwnerID": 1,
  "Title": "Business Lunch with Supplier",
  "Description": "Discussing supply needs for the next quarter",
  "StartTimezone": null,
  "Start": "2024-06-25T13:00:00.000Z",
  "End": "2024-06-25T14:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}, {
  "TaskID": 17,
  "OwnerID": 1,
  "Title": "Patient: Grace Lee - Wisdom Teeth Removal",
  "Description": "Surgical extraction of wisdom teeth",
  "StartTimezone": null,
  "Start": "2024-06-27T08:30:00.000Z",
  "End": "2024-06-27T10:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
}];

  export const customModelFields = {
    id: 'TaskID',
    title: 'Title',
    description: 'Description',
    start: 'Start',
    end: 'End',
    recurrenceRule: 'RecurrenceRule',
    recurrenceId: 'RecurrenceID',
    recurrenceExceptions: 'RecurrenceException'
  };
  const currentYear = new Date().getFullYear();
  const parseAdjust = eventDate => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };
  const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  export const displayDate = new Date(Date.UTC(currentYear, 5, 24));
  export const sampleData = baseData.map(dataItem => ({
    id: dataItem.TaskID,
    start: parseAdjust(dataItem.Start),
    startTimezone: dataItem.StartTimezone,
    end: parseAdjust(dataItem.End),
    endTimezone: dataItem.EndTimezone,
    isAllDay: dataItem.isAllDay,
    title: dataItem.Title,
    description: dataItem.Description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,
    roomId: dataItem.RoomID,
    ownerID: dataItem.OwnerID,
    personId: dataItem.OwnerID
  }));
  export const sampleDataWithResources = baseData.map(dataItem => ({
    id: dataItem.TaskID,
    start: parseAdjust(dataItem.Start),
    startTimezone: dataItem.StartTimezone,
    end: parseAdjust(dataItem.End),
    endTimezone: dataItem.EndTimezone,
    isAllDay: dataItem.isAllDay,
    title: dataItem.Title,
    description: dataItem.Description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,
    roomId: randomInt(1, 2),
    personId: randomInt(1, 2)
  }));
  export const schedulerData = baseData.map(dataItem => ({
    ...dataItem,
    Start: parseAdjust(dataItem.Start),
    End: parseAdjust(dataItem.End),
    PersonIDs: randomInt(1, 2),
    RoomID: randomInt(1, 2)
  }));