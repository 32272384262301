import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "./ExamsAndHygiene.css";
import { Icon } from "@progress/kendo-react-common";

const data = [
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None",
  },
];

const ActionCell = (props) => {
  return (
    <td>
      <Icon name="user" className="k-icon" />
      <Icon name="cart" className="k-icon" />
    </td>
  );
};

const ExamsAndHygiene = () => {
  return (
    <div className="exams-and-hygiene-main-wrapper">
      <Grid data={data} style={{ height: "500px", width: "100%" }}>
        <GridColumn field="treatment" title="Treatment" width="150px" />
        <GridColumn field="previous" title="Previous" width="150px" />
        <GridColumn field="due" title="Due" width="150px" />
        <GridColumn field="scheduled" title="Scheduled" width="150px" />
        <GridColumn field="interval" title="Interval" width="150px" />
        <GridColumn field="followUp" title="Follow-Up" width="150px" />
        <GridColumn field="comment" title="Comment" width="150px" />
        <GridColumn title="Actions" cell={ActionCell} width="150px" />
      </Grid>
    </div>
  );
};

export default ExamsAndHygiene;
