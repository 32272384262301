import React, { useState } from "react";
import MyScheduler from "../organisms/MyScheduler";
import Heading from "../atoms/Heading";
import { schedulerData } from "./schedulerData.js";

const SchedulerPage = () => {
  const rooms = [
    { text: "Op Room 101", value: 1 },
    { text: "Op Room 201", value: 2, color: "#FF7272" },
  ];

  const persons = [
    { text: "Peter Doe", value: 1, color: "#5392E4" },
    { text: "Alex John", value: 2, color: "#54677B" },
  ];

  const groups = {
    resources: ["Rooms", "Persons"],
    orientation: "horizontal",
  };

  const resources = [
    {
      name: "Rooms",
      data: rooms,
      field: "RoomID",
      valueField: "value",
      textField: "text",
      colorField: "color",
    },
    {
      name: "Persons",
      data: persons,
      field: "PersonIDs",
      valueField: "value",
      textField: "text",
      colorField: "color",
    },
  ];

  // Sample data to be passed as a prop to App component
  const baseData = [
    {
      TaskID: 1,
      OwnerID: 1,
      Title: "Team meeting",
      Description: "Discuss project progress",
      Start: new Date("2023-10-01T09:00:00.000Z"),
      End: new Date("2023-10-01T10:00:00.000Z"),
      isAllDay: false,
    },
    {
      TaskID: 2,
      OwnerID: 2,
      Title: "Client call",
      Description: "Follow-up with the client",
      Start: new Date("2023-10-02T11:00:00.000Z"),
      End: new Date("2023-10-02T12:00:00.000Z"),
      isAllDay: false,
    },
    // Add more tasks as needed
  ];

  return (
    <MyScheduler
      baseData={schedulerData}
      groups={groups}
      resources={resources}
    />
  );
};

export default SchedulerPage;
