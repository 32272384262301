import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess, logout } from "../../../store/authSlice";
import "./HomePageHeader.css";
import dentelLogo from "../../../assets/images/dentelLogo.png";
import { loginRequest } from "../../../authConfig";
import { Icon } from "@progress/kendo-react-common";
import Button from "../Button";
import ProfileBox from "./profileBox";
import patientData from "../../../utils/patient-data.json";
import SearchPatientGrid from "../../molecules/SearchPatientGrid";
import AddPatient from "../../pages/AddPatient";
import { useNavigate } from "react-router-dom";

const HomePageHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [showProfileBox, setShowProfileBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(patientData);
  const [show, setShow] = useState(false);
  const [showGrid, setShowGrid] = useState(false);

  const [selectedButton, setSelectedButton] = useState(0); // State for selected button

  useEffect(() => {
    if (inProgress === "none" && accounts?.length > 0 && !isAuthenticated) {
      const account = accounts[0];
      let profile = account?.idTokenClaims;
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((x) => {
          const user = {
            displayName: profile.given_name + " " + profile.family_name,
            email: profile.emails[0] || profile.userPrincipalName,
            photoUrl: "",
            accessToken: x.accessToken,
          };

          dispatch(loginSuccess(user));
        });
    }

    // Automatically trigger login if the user is not authenticated
    if (!isAuthenticated && inProgress === "none" && accounts?.length===0) {
      handleLogin();
    }
  }, [inProgress, isAuthenticated, accounts, instance, dispatch]);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
      localStorage.setItem("isLoggedIn", "true");
    } catch (error) {
      console.error("Login redirect failed:", error);
    }
  };

  const handleOnClick = () => {
    setShow(!show);
    setShowGrid(false);
  };

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.error("Logout redirect failed:", error);
    }
  };

  const dropdownOptions = [
    { text: "Option 1", value: 1 },
    { text: "Option 2", value: 2 },
  ];

  const toggleProfileBox = () => {
    setShowProfileBox(!showProfileBox);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() !== "") {
      const filtered = patientData.filter((patient) =>
        patient["Patient Name"].toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
      setShowGrid(filtered.length > 0);
    } else {
      setShowGrid(false);
    }
  };

  const handleButtonClick = (buttonIndex, route) => {
    setSelectedButton(buttonIndex); // Set the clicked button as selected
    navigate(route); // Navigate to the respective route
  };

  return (
    <div className="menu-container">
      <div className="menu-left">
        <img src={dentelLogo} alt="Logo" className="header-logo" />
        {
          isAuthenticated && <>
            <DropDownList
          data={dropdownOptions}
          defaultItem="Smiles Clinic"
          className="header-dropdown"
          style={{
            width: "150px",
          }}
        />
        <div className="search-plus-grid-container">
          <Input
            placeholder="Search patients"
            className="header-search"
            value={searchTerm}
            onChange={handleSearch}
          />
          {showGrid && (
            <SearchPatientGrid
              patientData={filteredData}
              handleOnClick={handleOnClick}
            />
          )}
        </div></>
        }
      
      </div>

      {/* Button Group with selected button state */}
      <div className="button-group">
        <Button
          look="flat"
          icon="calendar"
          className={`buttons-container-button ${
            selectedButton === 0 ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(0, "/home")}
        >
          Scheduler
        </Button>
        <Button
          look="flat"
          icon="user"
          className={`buttons-container-button ${
            selectedButton === 1 ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(1, "/patient")}
        >
          Patient
        </Button>
        <Button
          look="flat"
          icon="user"
          className={`buttons-container-button ${
            selectedButton === 2 ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(2, "/admin")}
        >
          Admin
        </Button>
        <Button
          look="flat"
          icon="chart-line-markers"
          className={`buttons-container-button ${
            selectedButton === 3 ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(3, "/analyzer")}
        >
          Analyzer
        </Button>
        <Button
          look="flat"
          icon="flag"
          className={`buttons-container-button ${
            selectedButton === 4 ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(4, "/hurdle")}
        >
          Hurdle
        </Button>
      </div>

      <div className="menu-right">
        <div className="icon-group">
          <Icon name="bell" className="header-icon" />
          <Icon name="comment" className="header-icon" />
          <Icon name="gear" className="header-icon" />
          <Icon name="question-circle" className="header-icon" />
        </div>

        {isAuthenticated && user ? (
          <div className="user-section">
            <img
              src={user?.photoUrl || "/placeholder.jpg"}
              alt="User Avatar"
              className="user-avatar"
              onClick={toggleProfileBox}
            />
            {showProfileBox && (
              <ProfileBox user={user} handleLogout={handleLogout} />
            )}
          </div>
        ) : (
          <Button look="flat" onClick={handleLogin}>
            Login
          </Button>
        )}
      </div>
      <div style={{ display: "none" }}>
        {show && <AddPatient show={show} handleOnClick={handleOnClick} />}
      </div>
    </div>
  );
};

export default HomePageHeader;
