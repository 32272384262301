import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/clinicService';
import { loginRequest } from '../authConfig';

// Thunk to fetch clinics

export const fetchClinics = createAsyncThunk(

  'clinics/fetchClinics',
  async (_, { getState, rejectWithValue }) => {

    const { accessToken } = getState().auth;
    console.log("getstate",getState())
    try {
        console.log("hereee")

    // const resp = await instance.acquireTokenSilent({
    //         ...loginRequest,
    //         account: accounts[0], // Account object from MSAL
    //       });
    //     console.log("att",resp)
      const response = await apiService.getClinics( accessToken);
      console.log("response",response)
      return response.data.results;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const clinicSlice = createSlice({
  name: 'clinics',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClinics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClinics.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchClinics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default clinicSlice.reducer;
