import React, { useState } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import { data } from "./ChartSampleData";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "@progress/kendo-theme-default/dist/all.css";
import "./Insurance.css"; // Link to the CSS file
import { Icon } from "@progress/kendo-react-common";

const Insurance = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const labelContent = (e) => e.category;

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <div>
      <div className="insurance-title">
        <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
          <TabStripTab title="First" />
          <TabStripTab title="Second" />
          <TabStripTab title="Other" />
        </TabStrip>
        <div className="tab-right-content">
          <span>Eligibility Checked 05/07/2024</span>
          <span className="tab-icons">
            <Icon name="file" />
            <Icon name="check" />
            <Icon name="user" />
          </span>
        </div>
      </div>
      <div>
        {selectedTab === 0 && (
          <div className="insurance-container">
            <div className="plan-details">
              <div className="plan-column">
                <p>Subscriber: Heena Raval</p>
                <p>Subscriber ID: 1234325</p>
                <p>Employer: Amazon</p>
                <p>Group ID: 12377</p>
                <p>Insurance: Provident Life And Accident Insurance</p>
                <p>Period: Calendar Year</p>
                <p>Group Name: grp123</p>
              </div>
              <div className="plan-column">
                <p>Relation: Self</p>
                <p>Coverage: Private Ins (Regular)</p>
                <p>Fees: Office Fees</p>
                <p>Ins Phone: 800-835-8670</p>
                <p>Plan Info: -</p>
              </div>
            </div>

            <div className="chart-container">
              <div>
                <h3>Annual Coverage</h3>
                <Chart>
                  <ChartSeries>
                    <ChartSeriesItem
                      type="donut"
                      data={data}
                      categoryField="kind"
                      field="share"
                    >
                      <ChartSeriesLabels
                        color="#fff"
                        background="none"
                        content={labelContent}
                      />
                    </ChartSeriesItem>
                  </ChartSeries>
                  <ChartLegend visible={false} />
                </Chart>
              </div>
              <div>
                <h3>Annual Deductible</h3>
                <Chart>
                  <ChartSeries>
                    <ChartSeriesItem
                      type="donut"
                      data={data}
                      categoryField="kind"
                      field="share"
                    >
                      <ChartSeriesLabels
                        color="#fff"
                        background="none"
                        content={labelContent}
                      />
                    </ChartSeriesItem>
                  </ChartSeries>
                  <ChartLegend visible={false} />
                </Chart>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Insurance;
