import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import DentelHomePage from "./components/pages/HomePage";
import SchedulerMenu from "./components/atoms/Menu/HomePageHeader";
import AdminPage from "./components/pages/AdminPage";
import PatientPage from "./components/pages/Patient/PatientPage";
import LoginPage from "./components/pages/LoginPage";
import { loginSuccess } from "./store/authSlice";
import PatientDocuments from "./components/pages/Patient/SubPages/PatientDocuments";
import { loginRequest } from "./authConfig";

// A PrivateRoute component to protect your routes
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("private route", isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const AppRouter = () => {
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Silent token acquisition and login state management
  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0 && !isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((tokenResponse) => {
          const profile = accounts[0].idTokenClaims;
          const user = {
            displayName: profile.given_name + " " + profile.family_name,
            email: profile.emails[0],
            accessToken: tokenResponse.accessToken,
          };
          dispatch(loginSuccess(user));
        });
    }
  }, [inProgress, accounts, instance, dispatch, isAuthenticated]);

  return (
    <div className="scheduler-homepage">
      <SchedulerMenu />
      <Routes>
        {/* Protected Routes */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <DentelHomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient"
          element={
            <PrivateRoute>
              <PatientPage />
            </PrivateRoute>
          }
        />

        {/* Login Route */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/doc" element={<PatientDocuments />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
