import React from "react";
import Button from "../atoms/Button";
import { Icon } from "@progress/kendo-react-common";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

const SearchPatientGrid = ({ patientData, handleOnClick}) => {


  return (
    <div className="search-and-grid">
      <Grid data={patientData} className="custom-grid">
        <GridColumn field="Patient Name" title="Patient Name" />
        <GridColumn field="Birthday" title="Birthday" />
        <GridColumn field="Phone" title="Phone" />
        <GridColumn field="Patient ID" title="Patient ID" />
        <GridColumn field="Status" title="Status" />
        <GridColumn field="Office" title="Office" />
        <GridColumn
          title="Go To"
          cell={(props) => (
            <td>
              <Icon name="user" className="k-icon" />
              <Icon name="cart" className="k-icon" />
              <Icon name="file-pdf" className="k-icon" />
            </td>
          )}
        />
      </Grid>

      <div className="actions-container">
        <Button className="add-patient-button" onClick={handleOnClick}>
          + Add Patient
        </Button>
        <Button className="add-family-button">+ Add Family</Button>

        <div className="checkbox-group">
          <Checkbox label="Referred Office" />
          <Checkbox label="Active Only" />
          <Checkbox label="Duplicate" />
        </div>
      </div>
    </div>
  );
};

export default SearchPatientGrid;
