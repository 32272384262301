import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import SchedulerDrawer from "../../atoms/Drawer/HomePageDrawer";
import "./PatientPage.css"; // Import custom styles
import { Button } from "@progress/kendo-react-buttons";
import Profile from "./TabComponents/Profile";
import ExamsAndHygiene from "./TabComponents/ExamsAndHygiene";
import PatientBackground from "./TabComponents/PatientBackground";
import Insurance from "./TabComponents/Insurance";

const PatientPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const renderTabButtons = () => {
    if (selectedTab === 0) {
      return (
        <>
          <Button icon="edit">Edit</Button>
          <Button icon="delete">Delete</Button>
        </>
      );
    } else if (selectedTab === 1) {
      return (
        <>
          <Button icon="calendar">All Visit</Button>
          <Button icon="plus">Recare</Button>
        </>
      );
    } else if (selectedTab === 2) {
      return (
        <>
          <Button icon="chat">Note template</Button>
          <Button icon="bell">Alert</Button>
        </>
      );
    } else {
      return (
        <>
          <Button icon="edit">Edit</Button>
          <Button icon="plus">Add Plan</Button>
        </>
      );
    }
  };

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>

      {/* Main content */}
      <div className="patient-page-main-content">
        <div className="tab-buttons-container">
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="Profile" />
            <TabStripTab title="Exams and Hygiene" />
            <TabStripTab title="Patient Background" />
            <TabStripTab title="Insurance" />
          </TabStrip>

          <div className="patient-tab-buttons">{renderTabButtons()}</div>
        </div>

        {/* Tab Content */}
        <div className="patient-tab-content">
          {selectedTab === 0 && <Profile />}
          {selectedTab === 1 && <ExamsAndHygiene />}
          {selectedTab === 2 && <PatientBackground />}
          {selectedTab === 3 && <Insurance />}
        </div>
      </div>
    </div>
  );
};

export default PatientPage;
