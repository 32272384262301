import React from "react";
import "./HomePage.css"; // Import custom styles
import SchedulerMenu from "../atoms/Menu/HomePageHeader";
import SchedulerSubMenu from "../atoms/Menu/HomePageSubHeader";
import SchedulerDrawer from "../atoms/Drawer/HomePageDrawer";
import SchedulerPage from "./SchedulerPage";

const DentelHomePage = () => (
  <div>
    <SchedulerSubMenu />

    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>

      <SchedulerPage />
    </div>
  </div>
);

export default DentelHomePage;
