import React from "react";
import "./Profile.css";

const Profile = () => {
  return (
    <div className="profile-container">
      <div className="profile-left">
        <div className="profile-icon">
          <div className="profile-img">K</div>
        </div>
        <div className="profile-info">
          <h2>Jenny Joshi (157197)</h2>
          <p>12/08/1999 (24y, 9mo), F</p>
          <p>Recare Visit: 07/25/24</p>
        </div>
        {/* Family members section */}
        <div className="family-members">
          <img
            src="https://via.placeholder.com/50"
            alt="Roni Joshi"
            className="family-member-img"
          />
          <img
            src="https://via.placeholder.com/50"
            alt="Nani Joshi"
            className="family-member-img"
          />
          <img
            src="https://via.placeholder.com/50"
            alt="Rani Joshi"
            className="family-member-img"
          />
        </div>
      </div>

      <div className="profile-right">
        <div className="details">
          <div className="detail-item">
            <h4>Patient Since</h4>
            <p>09/20/23, TEST b dsvgd</p>
          </div>
          <div className="detail-item">
            <h4>Source</h4>
            <p>AB AB,N</p>
          </div>
          <div className="detail-item">
            <h4>Total Production</h4>
            <p>$27,077.00</p>
          </div>
          <div className="detail-item">
            <h4>Family Balance</h4>
            <p>$11,527.10</p>
          </div>

          <div className="detail-item">
            <h4>Next Visit</h4>
            <p>10/18/24 (N/A)</p>
          </div>
          <div className="detail-item">
            <h4>Insurance</h4>
            <p>Healthnet (Regular), Sub...</p>
          </div>
          <div className="detail-item">
            <h4>Mobile</h4>
            <p>(123) 456-7896</p>
          </div>
          <div className="detail-item">
            <h4>Home</h4>
            <p>(901) 673-6304</p>
          </div>

          <div className="detail-item">
            <h4>E-mail</h4>
            <p>heena@appsvolt.com</p>
          </div>
          <div className="detail-item">
            <h4>Benefits</h4>
            <p>$23,388.80</p>
          </div>
          <div className="detail-item">
            <h4>Reach By</h4>
            <p>None</p>
          </div>
          <div className="detail-item">
            <h4>Patient ID</h4>
            <p>157197</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
