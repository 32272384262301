
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Provider } from "react-redux";
import store from "./store/store";
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Provider store={store}>

  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
  </Provider>,

  document.getElementById("root")
);
