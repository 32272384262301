import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dataReducer from './dataSlice';
import clinicReducer from './clinicSlice';


const store = configureStore({
    reducer: {
        auth: authReducer,
        data: dataReducer,
        clinics: clinicReducer,

    },
});

export default store;
