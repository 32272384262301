import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import "./PatientBackground.css";

const PatientBackground = () => {
  return (
    <div style={{ padding: "30px" }}>
      <div className="patient-background">
        <TextArea id="patient-background" rows={5} />
      </div>

      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "white",
          marginTop: "20px",
        }}
      >
        Patient Rating: None
      </div>
    </div>
  );
};

export default PatientBackground;
