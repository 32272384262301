import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_clientId, // Application (client) ID from the portal
    authority: process.env.REACT_APP_authority, // Your B2C sign-up/sign-in user flow
    knownAuthorities: [process.env.REACT_APP_knownAuthorities],
    redirectUri: window.origin, // Where the app redirects after authenticationn

    //secret- YGt8Q~OM1xuTQiemRRtXQHolsD8TIySJmwFQxbWW
  },
  cache: {
    cacheLocation: "sessionStorage", // Default is sessionStorage
    storeAuthStateInCookie: false, // Set to true for IE 11 support
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: ["openid", "profile","https://practiceoncloud.onmicrosoft.com/f56fb3ce-8dc3-4e60-b75e-1284870c2b2a/default"],
};
