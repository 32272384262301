import React from "react";
import SchedulerDrawer from "../../../atoms/Drawer/HomePageDrawer";
import "./PatientDocuments.css";
import { Icon } from "@progress/kendo-react-common";
import docFolderIcon from "../../../../assets/images/docFolderIcon.png";

const PatientDocuments = () => {
  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>
      <div className="main-content">
        {/* Header Section */}
        <header className="document-header">
          <h1 className="header-title">Patient Documents</h1>
          <input
            type="text"
            className="search-bar"
            placeholder="Search Patient"
          />
          <div className="header-buttons">
            <button className="header-btn">Download</button>
            <button className="header-btn">Upload</button>
            <button className="header-btn">Scan</button>
            <button className="header-btn">Imaging</button>
          </div>
        </header>

        {/* Body Section */}
        <div className="body-section">
          {/* Sidebar */}
          <aside className="document-sidebar">
            <ul>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Profile Picture
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Tooth Charts
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Insurance
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Perio Chart
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Clinical 1
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Perio Charts
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Health History
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Claim Attachments
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Miscellaneous
              </li>
              <li>
                <Icon name="file" style={{ color: "rgba(167, 176, 69, 1)" }} />
                Patient License
              </li>
            </ul>
          </aside>

          {/* Main Content Area */}
          <div className="main-documents">
            <div className="document-item">
              <img
                src={docFolderIcon}
                alt="Document"
                className="document-image"
              />
              <div className="document-details">
                <span className="document-name">Document 1</span>
                <span className="menu-icon">
                  <Icon name="more-vertical" />
                </span>
              </div>
              <div className="document-date">
                <span>04/23/2024</span>
              </div>
            </div>
            <div className="document-item">
              <img
                src={docFolderIcon}
                alt="Document"
                className="document-image"
              />
              <div className="document-details">
                <span className="document-name">Document 2</span>
                <span className="menu-icon">
                  <Icon name="more-vertical" />
                </span>
              </div>
              <div className="document-date">
                <span>04/23/2024</span>
              </div>
            </div>
            <div className="document-item">
              <img
                src={docFolderIcon}
                alt="Document"
                className="document-image"
              />
              <div className="document-details">
                <span className="document-name">Document 3</span>
                <span className="menu-icon">
                  <Icon name="more-vertical" />
                </span>
              </div>
              <div className="document-date">
                <span>04/23/2024</span>
              </div>
            </div>
            <div className="document-item">
              <img
                src={docFolderIcon}
                alt="Document"
                className="document-image"
              />
              <div className="document-details">
                <span className="document-name">Document 4</span>
                <span className="menu-icon">
                  <Icon name="more-vertical" />
                </span>
              </div>
              <div className="document-date">
                <span>04/23/2024</span>
              </div>
            </div>
            <div className="document-item">
              <img
                src={docFolderIcon}
                alt="Document"
                className="document-image"
              />
              <div className="document-details">
                <span className="document-name">Document 5</span>
                <span className="menu-icon">
                  <Icon name="more-vertical" />
                </span>
              </div>
              <div className="document-date">
                <span>04/23/2024</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDocuments;
