import React from "react";
import "./HomePageHeader.css";
import { Icon } from "@progress/kendo-react-common";

const ProfileBox = ({ user, handleLogout }) => {
  return (
    <div className="profile-box">
      <div className="profile-header">
        <img
          src={user?.photoUrl || "/placeholder.jpg"}
          alt="User Avatar"
          className="profile-avatar"
        />
        <div className="profile-details">
          <span>{user.displayName}</span>
          <span>Client ID: 37 | Office ID: 130</span>
        </div>
      </div>

      <ul className="profile-menu">
        <li>
          <Icon name="user" className="profile-icon" />
          Profile
        </li>
        <li>
          <Icon name="tasks" className="profile-icon" />
          Tasks
        </li>
        <li>
          <Icon name="pending" className="profile-icon" />
          Pending eRx
        </li>
        <li>
          <Icon name="clock" className="profile-icon" />
          I'm Working
        </li>
        <li>
          <Icon name="clock" className="profile-icon" />
          Time Punches
        </li>
        <li
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log("Logout clicked!"); // Debugging: Ensure this logs
            handleLogout(); // Ensure this is called
          }}
        >
          Logout
        </li>
      </ul>
    </div>
  );
};

export default ProfileBox;
