import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import "./DynamicForm.css"; // Custom styles if needed

const DynamicTabbedForm = ({ tabs, handleFormSubmit }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    // Handle tab selection (from TabStrip clicks)
    const handleTabSelect = (e) => {
        setSelectedTab(e.selected);
    };

    // Handle Next button click
    const handleNext = () => {
        if (selectedTab < tabs.length - 1) {
            setSelectedTab(selectedTab + 1);
        }
    };

    // Handle Previous button click
    const handlePrevious = () => {
        if (selectedTab > 0) {
            setSelectedTab(selectedTab - 1);
        }
    };

    return (
        <div className="custom-form">
            <TabStrip
                className="form-tabstrip"
                selected={selectedTab}
                onSelect={handleTabSelect}
            >
                {tabs.map((tab, index) => (
                    <TabStripTab key={index} title={tab.name}>
                        <div className="tab-content">
                            {tab.sections.map((section, sectionIdx) => (
                                <div key={sectionIdx} className="section-box">
                                    <div className="section-title">{section.sectionName}</div>
                                    <form>
                                        <div className="form-grid">
                                            {section.fields.map((field, fieldIdx) => (
                                                <div key={fieldIdx} className="form-group">
                                                    <label>
                                                        {field.label + ":"}
                                                        {field.required && (
                                                            <span style={{ color: "red" }}>*</span>
                                                        )}
                                                    </label>

                                                    {/* Handle input field using Kendo Input */}
                                                    {field.type === "input" && (
                                                        <Input
                                                            type={field.inputType || "text"}
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            required={field.required}
                                                        />
                                                    )}

                                                    {/* Handle select field using Kendo DropDownList */}
                                                    {field.type === "select" && (
                                                        <DropDownList
                                                            data={field.options}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            name={field.name}
                                                            required={field.required}
                                                            defaultItem={{ label: field.placeholder || "Select an option", value: "" }} // Add defaultItem for placeholder
                                                        />
                                                    )}


                                                    {/* Handle date field using Kendo DatePicker */}
                                                    {field.type === "date" && (
                                                        <DatePicker
                                                            name={field.name}
                                                            value={field.value || null}
                                                            onChange={field.onChange}
                                                            required={field.required}
                                                        />
                                                    )}

                                                    {/* Handle checkbox field using Kendo Checkbox */}
                                                    {field.type === "checkbox" && (
                                                        <Checkbox name={field.name} label={field.label} />
                                                    )}

                                                    {/* Handle button field using Kendo Button */}
                                                    {field.type === "button" && (
                                                        <Button onClick={field.onClick || (() => { })}>
                                                            {field.text}
                                                        </Button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </TabStripTab>
                ))}
            </TabStrip>

            <div className="footer-buttons">
                {/* Render Prev button as hidden on the first tab using Kendo Button */}
                <Button
                    className={`prev-button ${selectedTab === 0 ? "hidden" : ""}`}
                    onClick={handlePrevious}
                    disabled={selectedTab === 0}
                >
                    Prev
                </Button>

                {/* Render Next button on all tabs using Kendo Button */}
                <Button
                    className="next-button"
                    onClick={
                        selectedTab === tabs.length - 1 ? handleFormSubmit : handleNext
                    }
                >
                    {selectedTab === tabs.length - 1 ? "Submit" : "Next"}
                </Button>
            </div>
        </div>
    );
};

export default DynamicTabbedForm;
